import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import '@bakkt/components/src/font/font.css'
import App from './App'
import {store} from './store'
import {Provider} from 'react-redux'
import * as serviceWorker from './serviceWorker'
import application from './app/Application'
import {createRoot} from 'react-dom/client'

const TIME_ZONE = 'America/New_York'

window['store'] = store
const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
	<React.StrictMode>
		<Provider store={store}>
			<App />
		</Provider>
	</React.StrictMode>,
)
application.loadOnStartup()
application.setTimeZone(localStorage.getItem('timezone') || TIME_ZONE)
document.addEventListener(
	'contextmenu',
	function (e) {
		e.preventDefault()
	},
	false,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
